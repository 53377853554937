import React from 'react'
import './myList.css';
import { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';

const MyList = ({getAPICallWithToken, postAPICallWithToken, onShowCreateListBox, registeryListUpdated, isProduction, updateRegistryView}) => {

  const navigate = useNavigate();
  const [myList, setMyList] = useState([]);

  const registeryList = myList.map(iList => 
    <li className="listItem" key={iList.list_id} onClick={()=>{if (iList){handleRedirect(iList.list_id)}}}>
      <div>{iList.title}</div>
    </li>
  );
  

  const handleRedirect = (list_id) => {
    //console.log(list_id);
    
    navigate(`/myRegistry/${list_id}`);
  }
  
  const GetMyList = async () => {

    let endpointData = {
      endpoint : "getRegisteryLists"
    }

    let data = await getAPICallWithToken(endpointData);

    if (data.response.status !== 200 && data.response.status !== 201)
    {
      return alert(data.data.message);
    }
    else
    {
      //console.log(data.data.details);
      
      if (data.data && data.data.details)
      {

        //console.log(myList.length);

        setMyList(data.data.details);

        //console.log(myList);
      }
      
    }
  }


  const ShowCreateNewList = () => {
    onShowCreateListBox(true);
  }

  useEffect(()=>{
    //console.log("Getting my list.");
    let waitTime = 300;

    if (isProduction)
    {
      waitTime = 50;
    }

    setTimeout(GetMyList, waitTime);
  }, [registeryListUpdated])

  useEffect(()=>{
    updateRegistryView(false);
  }, [])

  return (
    <div>
      <div className="mainListArea">
        <h1>MY LISTS</h1>
        <ul className="listClass">
          {registeryList}
        </ul>
        <label className="NewListButton" onClick={ShowCreateNewList}>+ New List</label>
      </div>
    </div>
  );
}

export default MyList;