import React from 'react'
import './navBar.css';
import { useState } from 'react';


const NavBar = ({onShowLoginRegistrationBox, loggedIn, onLogOut, registryView, registryOwner, onSetShowInviteFriends}) =>{

	const onShowInviteFriends = (show) =>{
		onSetShowInviteFriends(show);
	}

	return(
		<div className="navBar">
			<div className="navBarEnd">
				{registryOwner && registryView ? <div className="listBtn" onClick={()=>onSetShowInviteFriends(true)}>Invite Friends</div> : ""}
				{!loggedIn ? <div className="signUpBtn" onClick={onShowLoginRegistrationBox}> Login / Join</div> : ""}
				{loggedIn ? <div className="signUpBtn" onClick={onLogOut}> Log Out </div> : ""}
			</div>
		</div>
	)
}


export default NavBar;