import React from 'react'
import './home.css';
import new_born_baby from "../../images/new_born_baby.jpg";
import birthday from "../../images/birthday.jpg";
import wedding_couple from "../../images/wedding_couple.jpg";

function Home() {
  return (
    <div>
      <div className="mainTitleArea">
        <h1>My Registry List</h1>
        <p>Make a simple registry list for the gifts you want. Add the gifts you want from any store in the world. Sign up and start making your list.</p>

        <div className="coverPicArea">
          <img src={new_born_baby}/>
          <img src={birthday}/>
          <img src={wedding_couple}/>
        </div>

      </div>
    </div>
  );
}

export default Home;