import React from 'react';
import { useState, useEffect } from 'react';
import './inviteFriends.css';

const InviteFriends =({onSetShowInviteFriends, postAPICallWithToken, activeList})=>{
	const [leftMargin, setLeftMargin] = useState("10%");
	const [inviteEmails, setInviteEmails] = useState("");

	function handleResize(){
		let windowWidth = window.innerWidth;


		if (windowWidth < 600)
		{
			setLeftMargin('5%');
		}
		else
		{
			setLeftMargin('calc(50% - 250px)');
		}
	}

	useEffect(()=>{
		//Add event listener
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const closeInviteFriendsPopup = ()=>{
		onSetShowInviteFriends(false);
	}

	const onInviteFriends = async ()=>{
	//console.log("Inviting friends...");

	//console.log(inviteEmails);

	let submitData = {
		endpoint : "addRegisteryListInvite",
		list_id : activeList.list_id,
		invite_email : inviteEmails
	}

	 let data = await postAPICallWithToken(submitData);

	 //console.log(data);

      if (data.response.status !== 200 && data.response.status !== 201)
      {
        //console.log(data);
        return alert(data.data.message);
      }
      else
      {
      	//console.log("Data is 200!");
        //console.log(data);

        alert("Friend's invited!");
        onSetShowInviteFriends(false); 
      }
	}

	return(
		<div className="blurBackground">
			<div className="createListBox" style={{left: leftMargin}}>
				<div className="title">INIVITE FRIEND'S</div>
				<div>seperate e-mail with commas</div>
				<hr/>
				<div className="inputLineRegistry">
					<label className="inputLabel">Friend's E-Mail:</label>
					<input className="inputField" type="text" name="inviteEmails" value={inviteEmails} onChange={(e)=> setInviteEmails(e.target.value)}/>
				</div>
				<div className="submitLine">
					<label className = "cancelButton" onClick={closeInviteFriendsPopup}>Cancel</label>
					<label className="submitButton" onClick={onInviteFriends}>Submit</label>
				</div>
			</div>
		</div>
	)
}

export default InviteFriends;