import React from 'react'
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './myRegistry.css';
import noaccess from '../../images/404.png';

function MyRegistry({loggedIn, getAPICallWithToken, postAPICallWithToken, userInfo, isProduction, updateRegistryView, isRegistryOwner, setActiveList, handleShowAddRegistryItem, updateItemListCount, handleShowLoginRegistrationBox}) {
  const {id} = useParams();
  const [listInfo, setListInfo] = useState({});
  const [backgroundColor, setBackgroundColor] = useState("#fff");
  const [textColor, setTextColor] =useState("#000");
  const [isOwner, setIsOwner] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);

  const [myList, setMyList] = useState([]);

  const [pageLoaded, setPageLoaded] = useState(false);
  const [mobileView, setMobileView] =useState(false);


  
//Change this to a table with rows and columns.
  const itemList = myList.map(iList => 
    <div className="listItemContainer" key={iList._id}>
      <div className="itemListRow">
        <div className="itemListTitle"><label>{iList.item_name}</label></div>
      </div>
      {mobileView ? <div className="itemListRow">
                <img className="itemListImageMobile" src={iList.item_image}/><div className="itemDescriptionBox"><div className="itemDescriptionMobile">{iList.item_description}</div></div>
            </div>
            :<div className="itemListRow">
                <img className="itemListImage" src={iList.item_image}/><div className="itemDescription">{iList.item_description}</div>
            </div>}
      { iList.item_notes != "" ?
            <div className="itemListRow">
              <div className="itemNewLine">
                <p className="itemNotes"><b>Notes:</b> {iList.item_notes}</p>
              </div>
            </div>
            : ""
      }
      {!iList.purchase.is_purchased ? <div className="itemListRow">
        <div className="itemNewLine">
          <a className="itemLink" href={iList.item_url} target="_blank">{iList.item_url}</a>
         </div>
      </div> : ""}
      
      <div className="itemListRow">
        <div className="itemNewLine">
          {iList.purchase.is_purchased ? 
            <div>
              <label className="purchasedLabel">Purchased By: {iList.purchase.purchased_by.first_name} {iList.purchase.purchased_by.last_name}</label>
              {iList.purchase.purchased_by.user_id == userInfo.user_id ? <label className="CancelPurchaseBtn" onClick={()=> onCancelMarkAsPurchased(iList)}>Cancel Purchased</label> : ""}
            </div> : 
            <div>
              {!isOwner ? <label className="PurchasedBtn" onClick={()=>onMarkAsPurchased(iList)}>Mark Purchased</label> : <label className="CancelPurchaseBtn" onClick={()=> onDeleteItem(iList)}>Remove Item</label>}
            </div>
          }
        </div>
      </div>
    </div>
  );


  function handleResize(){
    let windowWidth = window.innerWidth;

    //console.log("resizing...");

    if (windowWidth < 600)
    {
      setMobileView(true);
    }
    else
    {
      setMobileView(false);
    }
  }


  useEffect(()=>{
    //Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  
const onMarkAsPurchased = async (item)=>{
    if (loggedIn)
    {
      let answer = await window.confirm(`By clicking "OK" you are confirming that you're purchasing '${item.item_name}' for ${listInfo.owner_name}. Please make sure you send the item to the address in the delivery info above.`);

      if (answer)
      {
        let submitData = {
          endpoint : "registeryItemPurchased",
          _id : item._id
        }

        const data = await postAPICallWithToken(submitData);

        if (data.response.status !== 200 && data.response.status !== 201)
        {
          //console.log(data);
          return alert(data.data.message);
        }
        else
        {
          getListItems();
        }
      }
      else
      {
        //console.log("Cancelled the purchasing.")
      }
      
    }
  }

  const onCancelMarkAsPurchased = async (item)=>{
    if (loggedIn)
    {

      let answer = await window.confirm(`Are you sure you are no longer purchasing '${item.item_name}' for ${listInfo.owner_name}?`);

      if (answer)
      {
        let submitData = {
          endpoint : "registeryItemUnPurchase",
          _id : item._id
        }


        const data = await postAPICallWithToken(submitData);

        if (data.response.status !== 200 && data.response.status !== 201)
        {
          //console.log(data);
          return alert(data.data.message);
        }
        else
        {
          getListItems();       
        }
      }
      else
      {
        //console.log("Cancelled the cancel of purchasing.")
      }
    }
  }

  const onDeleteItem = async (item)=>{
    if (loggedIn && isOwner)
    {
      let answer = await window.confirm("Are you sure you want to remove this item?");

      if (answer)
      {
        let submitData = {
          endpoint : "deleteRegisteryItem",
          _id : item._id
        }

        const data = await postAPICallWithToken(submitData);

        if (data.response.status !== 200 && data.response.status !== 201)
        {
          //console.log(data);
          return alert(data.data.message);
        }
        else
        {
          getListItems();       
        }

      }
      else
      {
        //console.log("Cancelled removing item.");
      }

    }
  }

  const getListInfo = async ()=>{
    if (loggedIn)
    {
      //console.log("Getting list info...");
      let submitData = {
        endpoint : "getRegisteryListInfo",
        list_id : id
      }

      let data = await postAPICallWithToken(submitData);

      if (data.response.status !== 200 && data.response.status !== 201)
      {
        //console.log(data);
        return alert(data.data.message);
      }
      else
      {
        let detail = {};
        detail = data.data.details;

        setHasAccess(detail.hasAccess);
        setListInfo(detail);
        setActiveList(detail);
        setBackgroundColor(detail.backgroundColor);
        setTextColor(detail.textColor);
        
      }
    }
  }

  const getListItems = async ()=>{
    if (loggedIn)
    {
      //console.log("Getting list items: " + id);
      let submitData = {
        endpoint : "getRegisteryListItems",
        list_id : id
      }

      let data = await postAPICallWithToken(submitData);

      //console.log(data);

      if (data.response.status !== 200 && data.response.status !== 201)
      {
        return alert(data.data.message);
      }
      else
      {

        //console.log(data.data.details);
        setMyList(data.data.details);
        
      }
    }
    else
    {
      setMyList([]);
    }
  }

  useEffect(()=> {

    let waitTime = 300;

    if (isProduction)
    {
      waitTime = 50;
    }

    setTimeout(getListInfo, waitTime);

  }, [loggedIn]);

  useEffect(()=>{
    //console.log("List Info updated");
    //console.log(listInfo)

    if (loggedIn)
    {

      setTimeout(getListItems, 300);

      if (userInfo.user_id == listInfo.owner_id)
      {
        setIsOwner(true);
        isRegistryOwner(true);
        //console.log("User is owner");
      }
    }

  }, [listInfo]);

  useEffect(()=>{
    //console.log("Calling get list items from update item list count");
    getListItems();
  }, [updateItemListCount])

  useEffect(()=>{
    if (loggedIn)
    {

      if (userInfo.user_id == listInfo.owner_id)
      {
        setIsOwner(true);
        isRegistryOwner(true);
        //console.log("User is owner");
      }
      else
      {
        setIsOwner(false);
        isRegistryOwner(false);
        //console.log("User is not owner");
      }
    }
  }, [userInfo])

  useEffect(()=>{
    updateRegistryView(true);

    setTimeout(()=>{
      setPageLoaded(true);
    }, 500);

  }, [])

  
  return (
    <div className="RegistryListDisplayArea" style={{color : textColor, backgroundColor : backgroundColor}}>
      <div className="ListInfoArea">
        {pageLoaded ? <span>{loggedIn && hasAccess ? <h1>{listInfo.title}</h1> : <div className="NoAccessArea">
          
          {!loggedIn ? <div><h3>Ooops!!! You must be logged in and have access to view this list.</h3><div className="addItemBtn" onClick={handleShowLoginRegistrationBox}><label>Login/Join</label></div></div> : <div><h3>Ooops!!! You don't have access to this list.</h3><div className="NoAccessImageArea"><img src={noaccess}/></div></div>}

        </div>}</span> : ""}
        {loggedIn && hasAccess ? <div className="itemListDescriptionArea">
          <div className="itemListDescription"><label>Description:</label> <span>{listInfo.description}</span></div>
          <div className="DeliveryInfo">
            <label className="DeliveryInfoTitle">Delivery Information</label>
            <span><label className="DeliveryInfoLabel"> Address:</label><label className="DeliveryInfoInfo">{listInfo.deliveryAddress.address} </label></span>
            <span><label className="DeliveryInfoLabel"> City:</label><label className="DeliveryInfoInfo">{listInfo.deliveryAddress.city} </label></span>
            <span><label className="DeliveryInfoLabel"> Province:</label><label className="DeliveryInfoInfo">{listInfo.deliveryAddress.province} </label></span>
            <span><label className="DeliveryInfoLabel"> Country:</label><label className="DeliveryInfoInfo">{listInfo.deliveryAddress.country} </label></span>
            <span><label className="DeliveryInfoLabel"> Postal Code:</label><label className="DeliveryInfoInfo">{listInfo.deliveryAddress.postal_code} </label></span>
            <span><label className="DeliveryInfoLabel"> Phone Number:</label><label className="DeliveryInfoInfo">{listInfo.deliveryAddress.phone_number} </label></span>
            <span><label className="DeliveryInfoLabel"> Name:</label><label className="DeliveryInfoInfo">{listInfo.deliveryAddress.name}</label></span>
            {listInfo.deliveryAddress.note !== "" ? <span><label className="DeliveryInfoLabel"> Notes:</label><label className="DeliveryInfoInfo">{listInfo.deliveryAddress.note} </label></span> : ""}
          </div>
        </div> : ""}

        {hasAccess && loggedIn ? <div className="itemListClass">
                    {itemList}
                </div> :
                ""
        }

        {isOwner && loggedIn ? <div className="addItemBtn" onClick={handleShowAddRegistryItem}> + Add Item </div> : ""}
        <div className="FooterSpace"></div>
      </div>
        
    </div>
  );
}

export default MyRegistry;