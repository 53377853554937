import React from 'react'
import { useState, useEffect } from 'react';
import './createNewListModal.css';
import Switch from '../switch/switch';

const CreateNewListModal = ({onShowCreateListBox, postAPICallWithToken, triggerRegisteryListUpdate})=>{
	const [leftMargin, setLeftMargin] = useState("10%");
	const [selectedBackgroundColor, setSelectedBackgroundColor] = useState("#fff");
	const [selectedTextColor, setTextColor] = useState("#000");
	const [isPrivate, setIsPrivate] = useState(true);

	const [title, setTitle] =useState("");
	const [description, setDescription] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [province, setProvince] = useState("");
	const [country, setCountry] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [name, setName] = useState("");
	const [notes, setNotes] = useState("");


	function handleResize(){
		let windowWidth = window.innerWidth;


		if (windowWidth < 600)
		{
			setLeftMargin('5%');
		}
		else
		{
			setLeftMargin('calc(50% - 250px)');
		}
	}

	useEffect(()=>{
		//Add event listener
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const closeCreateNewList = () =>{
		onShowCreateListBox(false);
	}

	const setBackgroundColor = (color)=>{
		let backgroundColor = {backgroundColor : color};

		if (selectedBackgroundColor == color)
		{
			backgroundColor.border = "3px solid #aaa";
		}


		return backgroundColor
	}

	const selectBackgroundColor = (color)=> {
		setSelectedBackgroundColor(color);
	}

	const setSelectedTextColor = (color) =>{
		let backgroundColor = {backgroundColor : color};

		if (selectedTextColor == color)
		{
			backgroundColor.border = "3px solid #aaa";
		}


		return backgroundColor
	}

	const selectTextColor = (color) =>{
		setTextColor(color);
	}

	const setPrivacy = () =>{
		if (isPrivate)
		{
			setIsPrivate(false);
		}
		else
		{
			setIsPrivate(true);
		}
	}

	const submitCreateListForm = async ()=>{
		if (title == "")
		{
			return alert("Title can not be empty!");
		}


		if (address == "")
		{
			return alert("Address can not be empty!");
		}

		if (city == "")
		{
			return alert("City can not be empty!");
		}

		if (province == "")
		{
			return alert("Province can not be empty!");
		}

		if (country == "")
		{
			return alert("Country can not be empty!");
		}

		if (postalCode == "")
		{
			return alert("Postal Code can not be empty!");
		}

		if (phoneNumber == "")
		{
			return alert("Phone # can not be empty!");
		}

		if (name == "")
		{
			return alert("Name can not be empty!");
		}

		let deliveryAddress = {
			address : address,
			city : city,
			province : province,
			country : country,
			postal_code : postalCode,
			phone_number : phoneNumber,
			name : name,
			note : notes
		}

		let submitData = {
			title : title,
			description : description,
			backgroundColor : selectedBackgroundColor,
			textColor : selectedTextColor,
			isPrivate : isPrivate,
			deliveryAddress : deliveryAddress,
			endpoint : "createRegisteryList"
		}

		let data = await postAPICallWithToken(submitData);

	    if (data.response.status !== 200 && data.response.status !== 201)
	    {
	    	//console.log(data);
	    	return alert(data.data.message);
	    }
	    else
	    {
	      //console.log(data);

	      triggerRegisteryListUpdate();
	      onShowCreateListBox(false);
	    }
	}

	return(
		<div className="blurBackground">
			<div className="createListBox" style={{left: leftMargin}}>
				<div className="title">CREATE NEW LIST</div>
				<hr/>
				<div>
					
						<div className="inputLine">
							<label className="inputLabel">Title:</label>
							<input className="inputField" type="text" name="title" value={title} onChange={(e)=> setTitle(e.target.value)}/>
						</div>
						<div className="inputLine">
							<label className="inputLabel">Description:</label>
							<textarea className="inputTextArea" type="text" name="description" value={description} onChange={(e)=> setDescription(e.target.value)}/>
						</div>
						{/*
						<div className="inputLine">
							<label className="inputLabel">Background:</label>
							<div className="selectColorArea">
								<div className="selectColorBox" id="fff" style={setBackgroundColor('#fff')} onClick= {()=> selectBackgroundColor('#fff')}></div> 
								<div className="selectColorBox" id="494cf2"  style={setBackgroundColor('#494cf2')} onClick= {()=> selectBackgroundColor('#494cf2')}></div> 
								<div className="selectColorBox" id="eeff00"  style={setBackgroundColor('#eeff00')} onClick= {()=> selectBackgroundColor('#eeff00')}></div> 
								<div className="selectColorBox" id="00c907" style={setBackgroundColor('#00c907')} onClick= {()=> selectBackgroundColor('#00c907')}></div> 
								<div className="selectColorBox" id="e60526" style={setBackgroundColor('#e60526')} onClick= {()=> selectBackgroundColor('#e60526')}></div> 
								<div className="selectColorBox" id="000" style={setBackgroundColor('#000')} onClick= {()=> selectBackgroundColor('#000')}></div>
							</div>
						</div>
						

						<div className="inputLine">
							<label className="inputLabel">Text:</label>
							<div className="selectColorArea">
								<div className="selectColorBox" id="fff" style={setSelectedTextColor('#fff')} onClick= {()=> selectTextColor('#fff')}></div> 
								<div className="selectColorBox" id="494cf2"  style={setSelectedTextColor('#494cf2')} onClick= {()=> selectTextColor('#494cf2')}></div> 
								<div className="selectColorBox" id="eeff00"  style={setSelectedTextColor('#eeff00')} onClick= {()=> selectTextColor('#eeff00')}></div> 
								<div className="selectColorBox" id="00c907" style={setSelectedTextColor('#00c907')} onClick= {()=> selectTextColor('#00c907')}></div> 
								<div className="selectColorBox" id="e60526" style={setSelectedTextColor('#e60526')} onClick= {()=> selectTextColor('#e60526')}></div>
								<div className="selectColorBox" id="000" style={setSelectedTextColor('#000')} onClick= {()=> selectTextColor('#000')}></div> 
							</div>
						</div>
						*/}
						<div className="inputLine">
							<label className="inputLabel">Private:</label>
							<div className="selectColorArea">
								<Switch onClick={setPrivacy} value={isPrivate}/>
							</div>
						</div>
						
						<hr/>
						<h4>DELIVERY ADDRESS:</h4>
						<div className="inputLine">
							<label className="inputLabel">Address:</label>
							<input className="inputField" type="text" name="address" value={address} onChange={(e)=> setAddress(e.target.value)}/>
						</div>

						<div className="inputLine">
							<label className="inputLabel">City:</label>
							<input className="inputField" type="text" name="city" value={city} onChange={(e)=> setCity(e.target.value)}/>
						</div>

						<div className="inputLine">
							<label className="inputLabel">Province:</label>
							<input className="inputField" type="text" name="province" value={province} onChange={(e)=> setProvince(e.target.value)}/>
						</div>

						<div className="inputLine">
							<label className="inputLabel">Country:</label>
							<input className="inputField" type="text" name="country" value={country} onChange={(e)=> setCountry(e.target.value)}/>
						</div>

						<div className="inputLine">
							<label className="inputLabel">Postal Code:</label>
							<input className="inputField" type="text" name="postalCode" value={postalCode} onChange={(e)=> setPostalCode(e.target.value)}/>
						</div>

						<div className="inputLine">
							<label className="inputLabel">Phone #:</label>
							<input className="inputField" type="text" name="phone" value={phoneNumber} onChange={(e)=> setPhoneNumber(e.target.value)}/>
						</div>

						<div className="inputLine">
							<label className="inputLabel">Name:</label>
							<input className="inputField" type="text" name="name" value={name} onChange={(e)=> setName(e.target.value)}/>
						</div>

						<div className="inputLine">
							<label className="inputLabel">Notes:</label>
							<textarea className="inputTextArea" type="text" name="notes" value={notes} onChange={(e)=> setNotes(e.target.value)}/>
						</div>

						<div className="submitLine">
							<label className = "cancelButton" onClick={closeCreateNewList}>Cancel</label>
							<label className="submitButton" onClick={submitCreateListForm}>Submit</label>
						</div>
				</div>
			</div>
		</div>
	)
}


export default CreateNewListModal;