import React from 'react'
import { useState, useEffect } from 'react';
import './addRegistryItem.css';

const AddRegistryItem = ({postAPICallWithToken, extractURLData, manualShowAddRegistryItem, activeList, updateRegistryItemList})=>{
	const [leftMargin, setLeftMargin] = useState("10%");

	const [url, setURL] =useState("");
	const [itemName, setItemName] = useState("");
	const [itemDescription, setItemDescription] = useState("");
	const [itemNotes, setItemNotes] = useState("");
	const [itemImage, setItemImage] = useState("");
	const [spinner, setSpinner] = useState(false); 
	let getURLClicked = false;


	function handleResize(){
		let windowWidth = window.innerWidth;


		if (windowWidth < 600)
		{
			setLeftMargin('5%');
		}
		else
		{
			setLeftMargin('calc(50% - 250px)');
		}
	}


	useEffect(()=>{
		//Add event listener
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, []);


	const getURLInfo = async () =>{

		if (url !== "")
		{
			if (!getURLClicked)
			{
				setSpinner(true);
				getURLClicked = true;
				const data = await extractURLData(url);
				
				getURLClicked = false;

				//console.log(data);
				setSpinner(false);

				if (data.response.status !== 200 && data.response.status !== 201)
			    {
			    	
			    	return alert(data.data.message);
			    }
			    else
			    {
			      setItemName(data.data.title);
			      setItemDescription(data.data.description);
			      setItemImage(data.data.images[0]);

			    }

			}
			
		}
		else
		{
			return alert("Product Link can not be empty!");
		}
		
	}

	const closeAddItem = ()=>{
		manualShowAddRegistryItem(false)
	}

	const submitAddItem = async ()=>{
		if (itemName == "")
		{
			return alert("Product name can not be empty!");
		}

		if (itemDescription == "")
		{
			return alert("Description can not be empty!");
		}

		//console.log(activeList);

		let submitData = {
			list_id : activeList.list_id,
			item_url : url,
			item_name : itemName,
			item_description : itemDescription,
			item_notes : itemNotes,
			item_image : itemImage,
			endpoint : "addRegisteryItem"
		}

		let data = await postAPICallWithToken(submitData);

	    if (data.response.status !== 200 && data.response.status !== 201)
	    {
	    	//console.log(data);
	    	return alert(data.data.message);
	    }
	    else
	    {
	      //console.log(data);

	      updateRegistryItemList();

	      manualShowAddRegistryItem(false);
	    }


	}

	return(
		<div className="blurBackground">
			<div className="createListBox" style={{left: leftMargin}}>
				<div className="title">ADD NEW ITEM</div>
				<hr/>
					<div className="inputLineRegistry">
						<label className="inputLabel">Product Link (URL):</label>
						<input className="inputFieldURL" type="text" name="url" value={url} onChange={(e)=> setURL(e.target.value)} readOnly={spinner}/>
						{!spinner ? <label className="autoFillBtn" onClick={getURLInfo}>Auto Fill</label> : <label className="autoFillBtn">Auto Fill</label>}
					</div>


					<div className="inputLineRegistry">
						<label className="inputLabel">Product Name:</label>
						<input className="inputField" type="text" name="itemName" value={itemName} onChange={(e)=> setItemName(e.target.value)} readOnly={spinner}/>
					</div>

					<div className="inputLineRegistry">
						<label className="inputLabel">Description:</label>
						<textarea className="inputTextArea" type="text" name="itemDescription" value={itemDescription} onChange={(e)=> setItemDescription(e.target.value)} readOnly={spinner}/>
					</div>

					<div className="inputLineRegistry">
						<label className="inputLabel">Notes:</label>
						<textarea className="inputTextArea" type="text" name="itemNotes" value={itemNotes} onChange={(e)=> setItemNotes(e.target.value)} readOnly={spinner}/>
					</div>

					

					<div className="inputLineRegistry">
						<label className="inputLabel">Image Link (URL):</label>
						<input className="inputField" type="text" name="itemImage" value={itemImage} onChange={(e)=> setItemImage(e.target.value)} readOnly={spinner}/>
					</div>

					{itemImage !== '' ? <div className="inputLineImagePreviw">
						<label className="inputLabel">Image Preview:</label>
						<div className="imagePreviewBox">
							<img className="imagePreview" src={itemImage}/>
						</div>
					</div> : ""}

					<div className="submitLine">
						{!spinner ? <label className = "cancelButton" onClick={closeAddItem}>Cancel</label> : <label className = "cancelButton">Cancel</label>}
						{!spinner ? <label className="submitButton" onClick={submitAddItem}>Submit</label> : <label className="submitButton">Submit</label>}
					</div>
			</div>
		</div>
	)
}


export default AddRegistryItem;