import React from 'react'
import { useState, useEffect } from 'react';
import './loginRegisterModal.css';


const LoginRegisterModal = ({onShowLoginRegistrationBox, onCheckEmail, onAPICall, onSaveToken}) =>{

	const [leftMargin, setLeftMargin] = useState("10%");
	const [formName, setFormName] = useState("Check Email");
	const [email, setEmail] = useState("");
	const [first_name, setFirstName] = useState("");
	const [last_name, setLastName] = useState("");
	const [password, setPassword] = useState("");
	const [reenterPassword, setReenterPassword] = useState("");
	const [otp, setOtp] = useState("");
	const [canResendOTP, setCanResendOTP] = useState(false);
	const [resendOTPTimer, setResendOTPTimer] = useState("00:00");
	const [userInfo, setUserInfo] = useState({});
	const [loadResendArea, setLoadResendArea] = useState(false);
	


	async function handleSubmitEmail(){
		if (email == "")
		{
			alert("E-mail can not be empty!");
		}
		else
		{
			//console.log(email);
			let data = await onCheckEmail(email);

			//console.log(data);

			setUserInfo(data.user);

			if (!data.is_registered)
			{
				//console.log("User is not yet registered. Showing registration form.")
				setFormName("Registration");
			}
			else if (data.is_registered && !data.is_activated)
			{
				//console.log("User has not yet been activated. Showing activation page.");
				setFormName("Activation");
			}
			else if (data.is_registered && data.is_activated)
			{
				//console.log("User is registered and activated. Showing password input field.")
				setFormName("Input Password");
			}
		}
	}

	const handleRegistration = async ()=>{
		if (first_name == "")
		{
			return alert("First name can not be empty!");
		}
		else if (last_name == "")
		{
			return alert("Last name can not be empty!");
		}
		else if (password == "")
		{
			return alert("Password can not be empty!");
		}
		else if (password != reenterPassword)
		{
			return alert("Confirm password field does not match with password.");
		}
		
		let regData = {
			email : email,
    		first_name : first_name,
    		last_name : last_name,
    		password : password,
    		endpoint : 'registerUser'
		}

		let rData = await onAPICall(regData);

		//console.log(rData);

		if (rData.response.status == 200 || rData.response.status == 201)
		{
			//console.log("User has not yet been activated. Showing activation page.");
			setUserInfo(rData.data.details);
			//console.log(userInfo);
			setFormName("Activation");
		}
		else
		{
			alert(rData.data.message);
		}
	}

	const handleLogin = async()=> {
		if (password == "")
		{
			return alert("Password can not be empty!");
		}

		let loginData = {
			email : email,
			password : password,
			endpoint : "userLogin"
		}

		let rData = await onAPICall(loginData);
		//console.log(rData);

		if (rData.response.status == 200 || rData.response.status == 201)
		{
			//Save the token;
			onSaveToken(rData.data.token);
			setUserInfo(rData.data.user);
			//console.log("Login user info: ");
			//console.log(userInfo);

			onShowLoginRegistrationBox();
		}
		else
		{
			alert(rData.data.message);
		}
	}

	const handleOTPSubmit = async ()=>{
		if (otp == "")
		{
			return alert("Activation code can not be empty!");
		}

		let otpData = {
			endpoint : 'submitOTP',
			email : email,
			code : otp
		}

		let rData = await onAPICall(otpData);

		//console.log(rData);
		//console.log(rData.response.status);

		if (rData.response.status !== 200)
		{
			return alert(rData.data.message);
		}
		else
		{
			onSaveToken(rData.data.token);
			onShowLoginRegistrationBox();
		}
	}

	const handleResendOTP = async () =>{

		//console.log("Resending code...");

		let resendData = {
			email : email,
			endpoint : 'resendOTPCode'
		}

		let rData = await onAPICall(resendData);

		//console.log("OTP Resent");
		//console.log(rData);
		setLoadResendArea(false);
		setCanResendOTP(false);
		//console.log(rData.data.details);

		setUserInfo(rData.data.details);
	}

	function handleResize(){
		let windowWidth = window.innerWidth;


		if (windowWidth < 600)
		{
			setLeftMargin('5%');
		}
		else
		{
			setLeftMargin('calc(50% - 250px)');
		}
	}

	const handleResendOTPTimer = ()=>{
		
		//console.log(formName);
		//console.log(`Can handle resend otp? ${canResendOTP}`);

		if (formName == "Activation" && !canResendOTP)
		{
			//console.log("Going to handle resend otp timer");
			let otpExpireTime = new Date(userInfo.otp_expired_date).getTime();
			//console.log(userInfo.otp_expired_date);
			setLoadResendArea(true);
			setResendOTPTimer("00:00");
			
			let x = setInterval(()=>{
				let now = new Date().getTime();
				let distance = otpExpireTime - now;

				//console.log(distance);
				let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
				let seconds = Math.floor((distance % (1000 * 60)) / 1000);

				let minutesText = minutes.toString();

				if (minutes < 10)
				{
					minutesText = "0"+minutes.toString();
				}

				let secondsText = seconds.toString();

				if (seconds < 10)
				{
					secondsText = "0"+seconds.toString();
				}

				let countdownText = `${minutesText}:${secondsText}`;

				//console.log(seconds);



				setResendOTPTimer(countdownText);

				if (distance <= 0)
				{
					setCanResendOTP(true);
					clearInterval(x);
				}
			}, 1000)
			
		}	
	}

	useEffect(()=>{
		//console.log("User info loaded.");
		handleResendOTPTimer();
	}, [formName, userInfo]);

	useEffect(()=>{
		//Add event listener
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return(
		<div className="blurBackground">
			<div className="loginRegisterBox" style={{left: leftMargin}}>
				{/*The title area*/}
				<div className="title">LOGIN / JOIN</div>
				{
					(()=>{
						switch(formName)
						{
							
							case "Check Email":
								return (
									<div>
										<div className="inputLine">
											<label className="inputLabel">E-Mail:</label>
											<input className="inputField" type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
										</div>
										<div className="submitLine">
											<label className = "cancelButton" onClick={onShowLoginRegistrationBox}>Cancel</label>
											<label className="submitButton" onClick={handleSubmitEmail}>Submit</label>
										</div>
									</div>
								)
							break;

							case "Input Password":
								return (
									<div>
										<div className="inputLine">
											<label className="inputLabel">E-Mail:</label>
											<input className="inputField" type="text" name="email" value={email} readOnly={true}/>
										</div>

										<div className="inputLine">
											<label className="inputLabel">Password:</label>
											<input className="inputField" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
										</div>

										<div className="submitLine">
											<label className = "cancelButton" onClick={onShowLoginRegistrationBox}>Cancel</label>
											<label className="submitButton" onClick={handleLogin}>Submit</label>
										</div>
									</div>
								)
							break; 

							case "Registration":
								return(
									<div>
										<div className="inputLine">
											<label className="inputLabel">E-Mail:</label>
											<input className="inputField" type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} readOnly={true}/>
										</div>
										<div className="inputLine">
											<label className="inputLabel">First Name:</label>
											<input className="inputField" type="text" name="firstName" value={first_name} onChange={(e) => setFirstName(e.target.value)}/>
										</div>
										<div className="inputLine">
											<label className="inputLabel">Last Name:</label>
											<input className="inputField" type="text" name="lastName" value={last_name} onChange={(e) => setLastName(e.target.value)}/>
										</div>
										<div className="inputLine">
											<label className="inputLabel">Password:</label>
											<input className="inputField" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
										</div>
										<div className="inputLine">
											<label className="inputLabel">Confirm Password:</label>
											<input className="inputField" type="password" name="reenterPassword" value={reenterPassword} onChange={(e) => setReenterPassword(e.target.value)}/>
										</div>

										<div className="submitLine">
											<label className = "cancelButton" onClick={onShowLoginRegistrationBox}>Cancel</label>
											<label className="submitButton" onClick={handleRegistration}>Submit</label>
										</div>
									</div>

								)
							break; 

							case "Activation":
								return(
									<div>
										<div className = "activationCodeInfo">
											<label>Please check your e-mail to get your activation code.</label>
										</div>
										<div className="inputLine">
											<label className="inputLabel">Activation Code:</label>
											<input className="inputField" type="text" name="firstName" value={otp} onChange={(e) => setOtp(e.target.value)}/>
										</div>

										<div className="resendCodeLine">
											{loadResendArea ? <span>{canResendOTP ? <label className="resendCodeBtn" onClick={handleResendOTP}>Resend Code</label> : <label className="ResendOTPText">Resend OTP in: <b>{resendOTPTimer}</b></label>}</span> : ""}
										</div>

										<div className="submitLine">
											<label className = "cancelButton" onClick={onShowLoginRegistrationBox}>Cancel</label>
											<label className="submitButton" onClick={handleOTPSubmit}>Submit</label>
										</div>
									</div>
								)
							break; 
						}
					})()
				}
			</div>
		</div>

	);
}

export default LoginRegisterModal;