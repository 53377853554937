import './App.css';
import React from "react";
import { useState, useEffect } from 'react';
import { Routes, Route, useLocation} from "react-router-dom"
import Home from "./pages/home/home";
import MyList from "./pages/myList/myList";
import MyRegistry from "./pages/myRegistry/myRegistry";
import NavBar from "./components/navBar/navBar";
import LoginRegisterModal from "./components/loginRegisterModal/loginRegisterModal";
import CreateNewListModal from "./components/createNewList/createNewListModal";
import AddRegistryItem from "./components/addRegistryItem/addRegistryItem";
import InviteFriends from "./components/inviteFriends/inviteFriends";

//const API_URL = 'http://localhost:3001/api'
const API_URL = '/api'

const App = () => {
  const [isProduction, setIsProduction] = useState(false);
  const [token, setToken] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [registeryListUpdated, setRegisteryListUpdated] = useState(0);
  const [showLoginRegistrationBox, setShowLoginRegistrationBox] = useState(false);
  const [showCreateListBox, setShowCreateListBox] = useState(false);
  const [showAddRegistryItem, setShowAddRegistryItem] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [registryView, setRegistryView] = useState(false);
  const [registryOwner, setRegistryOwner] = useState(false);
  const [jsonLinkKey, setJsonLinkKey] = useState("");
  const [activeList, setActiveList] = useState({});
  const [showInviteFriends, setShowInviteFriends] = useState(false);
  const [updateItemListCount, setUpdateItemListCount ] = useState(0);
  let location = useLocation();



  const checkEmail = async (email) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({"email" : email})
    }

    const response = await fetch(`${API_URL}/checkEmail`, requestOptions)
    const data = await response.json();

    //console.log(data);
    return data;
  }

  const postAPICall = async (submitData) => {
    //console.log(submitData);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(submitData)
    }

    const response = await fetch(`${API_URL}/${submitData.endpoint}`, requestOptions)
    const data = await response.json();

    const updateData = {
      response : response,
      data : data
    }

    return updateData;
  }

  const postAPICallWithToken = async (submitData) => {
    //console.log(submitData);

    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(submitData)
    }

    const response = await fetch(`${API_URL}/${submitData.endpoint}`, requestOptions)
    const data = await response.json();

    const updateData = {
      response : response,
      data : data
    }

    return updateData;
  }

  const getAPICallWithToken = async(endpoint) => {
    //console.log(endpoint);
    //console.log(token);

    const requestOptions = {
      method : 'GET',
      headers: { 
        'Authorization' : `Bearer ${token}`
      },
    }

    //console.log(requestOptions);

    const response = await fetch(`${API_URL}/${endpoint.endpoint}`, requestOptions);
    const data = await response.json();

    const updateData = {
      response : response,
      data : data
    }

    return updateData;
  }

  const saveToken = (tokn) => {
    setToken(tokn);

    localStorage.setItem('token', JSON.stringify(tokn));
  }

  const getToken = ()=>{
    let tokn = JSON.parse(localStorage.getItem('token'));
    setToken(tokn);

    return token;
  }

  const triggerRegisteryListUpdate = ()=>{
    setRegisteryListUpdated((registeryListUpdated) => registeryListUpdated + 1);
    //console.log(registeryListUpdated);
  }

  useEffect(()=> {
    let tokn = getToken();

    if (tokn && tokn !== '')
    {
      setLoggedIn(true);
    }

    //console.log("Token: "+tokn);
    //console.log("Is logged in: "+loggedIn);
  })

  const setLogOut = ()=> {
    setToken("");
    localStorage.removeItem('token');
    setRegistryOwner(false);
    setLoggedIn(false);
    //console.log("Log out set!");
    window.location.replace("/");
  }


  const handleShowLoginRegistrationBox = () => {
    if(!showLoginRegistrationBox)
    {
      setShowLoginRegistrationBox(true);
    } else
    {
      setShowLoginRegistrationBox(false);
    }
  }

  const handleShowAddRegistryItem = () => {

    //console.log("Add Item clicked");
    if (!showAddRegistryItem)
    {
      setShowAddRegistryItem(true);
    }
    else
    {
      setShowAddRegistryItem(false);
    }
  }

  const manualShowAddRegistryItem = (show) =>{
    setShowAddRegistryItem(show);
  }


  const getUserInfo = async () =>{
    let data = await getAPICallWithToken({endpoint : "getUserInfo"});

    if (data.response.status !== 200 && data.response.status !== 201)
      {
        //console.log(data);
        return alert(data.data.message);
      }
      else
      {
        let user = {};
        user = data.data.user;
        setUserInfo(user);
      }
  }

  const getJSONLinkAPIKey = async ()=>{
    let data = await getAPICallWithToken({endpoint : "jsonlinkKey"})

    if (data.response.status !== 200 && data.response.status !== 201)
    {
      //console.log(data);
      return alert(data.data.message);
    }
    else
    {
      //console.log(data);
      let keyData = {};
      keyData = data.data.keyData.key;
      setJsonLinkKey(keyData);
    }
  }

  const extractURLData = async (url)=>{
    const apiUrl = `https://jsonlink.io/api/extract?url=${url}&api_key=${jsonLinkKey}`;

    const response = await fetch(apiUrl); 
    const data = await response.json();

    const updateData = {
      response : response,
      data : data
    }

    return updateData;

  }

  useEffect(()=>{
    if (loggedIn)
    {
      setTimeout(getUserInfo, 100);
      setTimeout(getJSONLinkAPIKey, 300);

      //console.log("I am logged in!");
    }
  }, [loggedIn]);

  useEffect(()=>{
    setShowCreateListBox(false);
    setShowAddRegistryItem(false);
  }, [location])


  const handleShowCreateListBox = (show)=>{
    setShowCreateListBox(show);
  }

  const updateRegistryView = ((isRegistry)=>{
    setRegistryView(isRegistry);
    setShowAddRegistryItem(false);
  })

  const isRegistryOwner = ((isOwner)=>{
    setRegistryOwner(isOwner);
  })

  const onSetShowInviteFriends = ((show)=>{
    setShowInviteFriends(show);
  })

  const updateRegistryItemList = ()=>{
    setUpdateItemListCount(updateItemListCount + 1);
  }


  return (
    <div>
      {showLoginRegistrationBox ? <LoginRegisterModal onShowLoginRegistrationBox={handleShowLoginRegistrationBox} onCheckEmail={checkEmail} onAPICall={postAPICall} onSaveToken={saveToken}/> : ''}
      {showCreateListBox ? <CreateNewListModal onShowCreateListBox={handleShowCreateListBox} postAPICallWithToken={postAPICallWithToken} triggerRegisteryListUpdate={triggerRegisteryListUpdate}/> : ""}
      {showAddRegistryItem && registryView && registryOwner ? <AddRegistryItem postAPICallWithToken={postAPICallWithToken} extractURLData={extractURLData} manualShowAddRegistryItem={manualShowAddRegistryItem} activeList={activeList} updateRegistryItemList={updateRegistryItemList}/> : ""}
      {showInviteFriends && registryOwner && registryView ? <InviteFriends onSetShowInviteFriends={onSetShowInviteFriends} postAPICallWithToken={postAPICallWithToken} activeList={activeList}/> : ""}
      <NavBar onShowLoginRegistrationBox={handleShowLoginRegistrationBox} loggedIn={loggedIn} onLogOut={setLogOut} registryView={registryView} registryOwner={registryOwner} handleShowAddRegistryItem={handleShowAddRegistryItem} onSetShowInviteFriends={onSetShowInviteFriends}/>


      
      <Routes>
          {!loggedIn ? <Route path="/" element={ <Home/> } /> : <Route path="/" element={ <MyList getAPICallWithToken={getAPICallWithToken} postAPICallWithToken={postAPICallWithToken} onShowCreateListBox={handleShowCreateListBox} registeryListUpdated={registeryListUpdated} isProduction={isProduction} updateRegistryView={updateRegistryView}/> } /> }
          <Route path="/myRegistry/:id" element={<MyRegistry loggedIn={loggedIn} getAPICallWithToken={getAPICallWithToken} postAPICallWithToken={postAPICallWithToken} userInfo={userInfo} isProduction={isProduction} updateRegistryView={updateRegistryView} isRegistryOwner={isRegistryOwner} setActiveList={setActiveList} handleShowAddRegistryItem={handleShowAddRegistryItem} updateItemListCount={updateItemListCount} handleShowLoginRegistrationBox={handleShowLoginRegistrationBox}/>}/>
      </Routes>
    </div>
  );
}

export default App;
